import { Link } from "gatsby";
import React from "react";
import styles from './Footer.module.css'


const InstagramIcon = props => {
  return (
    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="w-8 h-8 inline" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>  )
}
const LinkedInIcon = props => {
  return (
  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" className="w-8 h-8 inline" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>)
}

const Footer = ({ siteTitle, siteFooter }) => (
  <>
    {/* <div className="container mx-auto  px-3 pt-6">
    <p><strong>We are proudly supported by European Union and Latvian Investment and Development Agency</strong></p>
      <p className={styles.eu}>Unicorns Unlimited SIA has concluded an agreement with Latvian Investment and Development Agency (LIAA) on receipt of Incubation support within the framework of the ERDF Project No. 3.1.1.6/16/I/001 Regional Business Incubators and Creative Industries Incubator.</p>
    <img src={'/eu-logo.png'} alt="Latvian Investment and Development Agency (LIAA) logo" className={styles.eulogo} />

    </div> */}
  <footer className={styles.footer}>
    <div className="container mx-auto  px-8">
      <div className="w-full flex flex-col md:flex-row py-6">
        <div className="flex-1 mb-6">
          
            <img src={'/logo-vertical-inverted.png'} alt={siteTitle} className={styles.logo} />

        </div>

        {/* <div className="flex-1">
          <p className="uppercase text-green md:mb-6">Services</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a href="">
                <span className="hover:underline text-blue hover:text-greylight">
                  Online store audit
                </span>
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href=""
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                CRO
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href=""
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                AB Testing
              </a>
            </li>
          </ul>
        </div> */}
        <div className="flex-1">



          <div className="mt-8 md:mt-0 mb-8">

               <a
                href="https://www.instagram.com/unicornaudit/"
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                <InstagramIcon />
              </a>

              <a
                href="https://www.linkedin.com/company/unicorn-audit/"
                className="no-underline hover:underline ml-8 text-blue hover:text-greylight"
              >
                <LinkedInIcon />
              </a>


          </div>


          <div>
          <ul className="list-reset mb-6 flex flex-col sm:flex-row gap-8">
            <li className="mt-2  mr-2 md:block md:mr-0">
              <Link
                to="/"
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                Home
              </Link>
            </li>
            <li className="mt-2 inline mr-2 md:block md:mr-0">
              <Link
                to="/about"
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                About
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/blog"
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                Blog
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                to="/contact"
                className="no-underline hover:underline text-blue hover:text-greylight"
              >
                Contact
              </Link>
            </li>
          </ul>

            </div>



            <div className="mt-16">



              <div className="font-bold text-bluegrey mb-2">Call us</div>

              <div>
                <a
                  href="tel:+37129875213"
                  className="underline text-xl sm:text-2xl lg:text-3xl  font-light hover:underline text-white hover:text-greylight"
                >
                  +371 2987 5213
                </a>
              </div>


              <div className="font-bold text-bluegrey mb-2 mt-12">Write to us</div>

              <div>
                <a
                  href="mailto:info@unicornaudit.com"
                  className="underline text-xl sm:text-2xl lg:text-3xl  font-light hover:underline text-white hover:text-greylight"
                >
                  info@unicornaudit.com
                </a>
              </div>


              <div className="font-bold text-bluegrey mb-2 mt-12">Meet us</div>

              <div>
                <a
                  href="https://calendly.com/unicorn-audit/15"
                  className="underline text-xl sm:text-2xl lg:text-3xl  font-light hover:underline text-white hover:text-greylight"
                >
                  Book a meeting
                </a>
              </div>




            </div>




          
        </div>
        <div className="flex-1">

         
        </div>
        <div className="flex-1">

        </div>
      </div>
    </div>
  </footer>
  <footer className={styles.finalfooter}>
    <div className="container mx-auto text-bluegrey px-8 text-sm">

    
          
            <div className="block lg:inline mr-8">{siteFooter}</div>


            <Link
                to="/terms"
                className="no-underline  mr-8 block lg:inline hover:underline text-blue hover:text-greylight"
              >
                Terms and Conditions
              </Link>


            <Link
                to="/privacy"
                className="no-underline mr-8 block lg:inline hover:underline text-blue hover:text-greylight"
              >
                Privacy policy
              </Link>

    </div>
  </footer>
  </>
);

export default Footer;
