import { Link } from "gatsby";
import React, { useState} from "react";
import CTALink from "./CTALink";
import styles from './header.module.css'; // change
import stylesCTA from './CTALink.module.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const MenuIcon = props => {
  return (
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="w-8 h-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>)
}
const CloseIcon = props => {
  return (
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="w-8 h-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>)
}

const Header = ({ showNav, siteTitle, scrolled, navMenuItems = [], textWhite = false, path }) => {

  const [showMenu, toggleMenu] = useState(false);
  
  
  let headerClass = `fixed w-full z-30 top-0 text-white ${styles.header}`;
  headerClass += scrolled ? ` bg-white shadow header__scrolled ${styles.header_scrolled}` : "";

  let navActionClass =
    "mx-auto lg:mx-0 hover:underline font-bold rounded-md mt-4 lg:mt-0 py-4 px-8 shadow m-4 lg:mb-0  ";
  navActionClass += !textWhite || !scrolled ? ` hidden bg-white text-gray-800 ${stylesCTA.button}` : "";
  navActionClass += textWhite || scrolled ? ` gradient ${stylesCTA.buttonScrolled}` : "";
  navActionClass += showMenu ? " hidden" : "  ";


  let navContentClass = "w-full lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 text-black z-20 rounded-lg  ";
    navContentClass += !textWhite || !scrolled ? " pt-6 " : "";
    navContentClass += scrolled ? " flex " : "";
    // navContentClass += textWhite || scrolled ? " bg-white" : "";
  navContentClass += showMenu ? " block m-3 lg:m-0 bg-white" : "  hidden lg:block";


  let titleClass = "toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl";
  titleClass += !textWhite || scrolled ? " " : "";
  titleClass += textWhite || !scrolled ? " " : "";

  let linkClass = "p-6 lg:p-3 block text-purple"

  let navContainerClass = `w-full container mx-auto  mt-0 py-2 flex lg:block  justify-between`
  navContainerClass += scrolled ? " lg:flex  " : "";
  navContainerClass += showMenu ? "  bg-white shadow-lg " : "  ";

  let menuIconClass = "text-black block lg:hidden p-3 mr-3  order-10 bg-white rounded-md shadow text-purple";
  menuIconClass += showMenu ? " self-start": " self-end";

  return (
    <nav id="header" className={headerClass}>

      <div className={navContainerClass}>
        
        {showMenu ? null : 
        <div className="pl-4 flex items-center">
          <Link id="siteTitle" className={titleClass} to="/">
            <img src={'/logo-horizontal-mixed.png'} alt={siteTitle} className={styles.logo} />
          </Link>
        </div>}


        <button onClick={() => toggleMenu(!showMenu)} className={menuIconClass} >
          {showMenu ? <CloseIcon /> : <MenuIcon /> }
        </button>

        <MessengerCustomerChat
          pageId="108389124450165"
          appId="180062514165570"
          htmlRef="fbchat"
        />



        {showNav && navMenuItems && (
          <div className={navContentClass} id="nav-content">
            <ul className="list-reset lg:flex flex-1 items-center">
              {navMenuItems.map((i, idx) => (
                <li className="lg:mr-3" key={idx}>
                  <CTALink {...i} buttonActionClass={navActionClass} linkClass={linkClass} currentPath={path} />
                </li>
              ))}
            </ul>
          </div>
        )}



      </div>


    </nav>
  );
};

export default Header;
