import React from "react";
import { Link } from "gatsby";
import AnchorLink from 'react-anchor-link-smooth-scroll' 


const CTALink = props => {
  let link = props.route || props.link || "#";


  // var buttonComponent = props.smoothScroll ? AnchorLink : Link;

  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = ['/', props.landingPageRoute.slug.current].join('');
  }

    // External button
    if (props.link && props.kind === "button") {
      return (
        <a 
          href={props.link} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={props.buttonActionClass || ""}
        >
          {props.title}
        </a>
      );
    }

  if (props.kind === "button") {

    return props.smoothScroll ? <AnchorLink
    href={link}
    offset="100"
    className={props.buttonActionClass || ""}
  >
    {props.title}
  </AnchorLink> : <Link
        to={link}
        className={props.buttonActionClass || ""}
      >
        {props.title}
      </Link>
  
    // return (
    //   <AnchorLink
    //     href={link}
    //     to={link}
    //     className={props.buttonActionClass || ""}
    //   >
    //     {props.title}
    //   </AnchorLink>
    // );
  }

  // External
  if (props.link) {
    return (
      <a href={props.link} target="_blank" rel="noopener noreferrer" className={props.linkClass || ""}>
        {props.title}
      </a>
    );
  }
  return (
    <Link to={link}  className={props.currentPath == link ? `${props.linkClass} underline text-gray-800` : `${props.linkClass}`}>
      {props.title}
    </Link>
  );
};

export default CTALink;
